import hutchlogo from '../assets/img/hutchstudiologo.png';
import fearlesslogo from '../assets/img/fearlesslogo.jpg';
import tsulogo from '../assets/img/texasstate.jpg';
import earthfundlogo from '../assets/img/earthfundlogo.jpg';
import sandCountylogo from '../assets/img/sandCountylogo.jpg';
import msulogo from '../assets/img/msulogo.png';

//array for images
export const SliderData = [
    
    {
        image: fearlesslogo
    },
    {
        image: tsulogo
    },
    {
        image: hutchlogo
    },
    {
        image: earthfundlogo
    },
    {
        image: msulogo
    },
    {
        image: sandCountylogo
    }


]